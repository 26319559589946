<template>
    <modal ref="rechazarCambios" titulo="Descartar cambios" icon="rechazar-pedido" @guardar="descartar">
        <div class="row mx-0 mt-3 justify-center">
            Está apunto de salir
        </div>
        <div class="row mx-0 mb-3 justify-center">
            ¿Desea descartar los cambios realizados?
        </div>
    </modal>
</template>

<script>
export default {
    methods: {
        toggle(){
            this.$refs.rechazarCambios.toggle()
        },
        descartar(){
            this.$emit('descartar')
        }
    }
}
</script>

<style lang="css" scoped>
</style>
